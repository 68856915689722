export class ServiceType {
  constructor(
    public id?: number,
    public name?: string,
    public note?: string,
    public idCompany?: number,
    public status?: number,
    public parameterMax?: number, //int
    public parameterAvg?: number,
    public distributionPercentage?: number,
    public communicationCosts?: number,
    public createdAt?: Date,
    public updatedAt?: Date,
  ) {
  }
}


export class ServiceTypeConfiguration {
  constructor(
    public id?: number,
    public idServiceType?: number,
    public serviceTypeName?: string,
    public parameterMax?: number,
    public parameterAvg?: number,
    public distributionPercentage?: number,
    public communicationCosts?: number,
    public serviceValue?: number,
    public maximumImageDamageValue?: number,
  ) {
  }
}
