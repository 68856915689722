import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

export class DateUtils {

  public static fromDateToDataStruct(date: Date): NgbDateStruct {
    return date ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    } : null;
  }

  public static fromDataStructToDate(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
  }

}
